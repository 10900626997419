<template>
    <div>
        <search-header :currentActive="-1"></search-header>
        <div class="container p-0 my-4">
            <img class="img-fluid" src="@/assets/img/ad_nongyong_1.png">
        </div>

        <div class="container">
            <div class="row">
                <div   v-if="dict"  class="col-12 py-2 border screen">
                    <div  v-if="area"  class="row mb-2 mt-2 align-content-center">
                        <div class="col-1 text-secondary mt-1">地区:</div>
                        <div class="col">
                            <div class="row">
                                <a href="javascript:;" class="mr-3 px-2 py-1" :class="{'active' : chooseArea.province == p.code}" @click="onScreenClick('province',p.code)" v-for="p in area.province" :key="p.code">{{p.name}}</a>
                            </div>
                            <div v-if="getCity" class="row mt-2" >
                                <a href="javascript:;" class="mr-3 px-2 py-1" :class="{'active' : chooseArea.city == c.code}"  @click="onScreenClick('city',c.code)" v-for="c in getCity" :key="c.code">{{c.name}}</a>
                            </div>
                            <div v-if="getArea" class="row mt-2" >
                                <a href="javascript:;" class="mr-3 px-2 py-1" :class="{'active' : chooseArea.area == a.code}"  @click="onScreenClick('area',a.code)" v-for="a in getArea" :key="a.code">{{a.name}}</a>
                            </div>
                        </div>
                    </div>

                    <hr class="my-3"/>

                    <div class="row mb-2 align-content-center">
                        <div class="col-1 text-secondary mt-1">用途:</div>
                        <div class="col">
                            <div class="row">
                                <a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('landUseTypeP','')" :class="{'active':  useLandClassId == ''}">全部</a>
                                <a href="javascript:;" @click="onScreenClick('landUseTypeP',u.id)" v-for="u in dict.landUseType" :key="u.id" class="mr-3 px-2 py-1" :class="{'active' : u.id == useLandClassId}" >{{u.text}}</a>
                            </div>
                            <div v-if="useLandClassId" class="row mt-2">
                                <a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('landUseTypeC',useLandClassId)" :class="{'active': para.param.landUse == useLandClassId}">全部</a>
                                <a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('landUseTypeC',child.id)"    v-for="child in getChildUseLandList" :key="child.id" :class="{ 'active': child.id==para.param.landUse  }" >{{child.text}}</a>
                            </div>
                        </div>
                    </div>

                  <hr class="my-3"/>
                  <div class="row ">
                    <div class="col-1 text-secondary mt-1">标签:</div>
                    <div class="col">
                      <div class="row ">
                        <a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('serviceTagP','')"
                           :class="{'active':  tags == ''}">全部</a>
                        <a v-for="m in dict.landTags.filter(x=> x.level == 1  )" :key="m.id" href="javascript:;" @click="onScreenClick('serviceTagP',m.id)"
                           class="mr-3 px-2 py-1 "  :class="{'active' : m.id == tags}" >{{ m.text }}</a>
                      </div>
                      <div v-if="tags" class="row mt-2">
                        <a href="javascript:;" class="mr-3 px-2 py-1" @click="onScreenClick('serviceTagC', tags)"
                           :class="{'active': para.param.serviceTag == tags}">全部</a>
                        <a href="javascript:;" class="mr-3 px-2 py-1"
                           @click="onScreenClick('serviceTagC',n.id)" v-for="n in getTagsList"
                           :key="n.id"
                           :class="{ 'active': n.id==para.param.serviceTag  }">{{n.text}}</a>
                      </div>
                    </div>
                  </div>


                    <hr class="my-3"/>
                    <div class="row">
                        <div class="col-1 text-secondary mt-1">流转:</div>
                        <div class="col">
                            <div class="row">
                                <a href="javascript:;" class="mr-3 px-2 py-1" @click="para.param.flowType = '', onScreenClick('circulationType','')" :class="{'active': para.param.flowType == ''}">全部</a>
                                <a href="javascript:;"  @click="onScreenClick('circulationType',v.id)" v-for="v in dict.circulationType"  :key="v.id"
                                   class="mr-3 px-2 py-1"
                                   :class="{'active':para.param.flowType==v.id }">{{ v.text }}</a>
                            </div>
                        </div>
                    </div>
                    <hr class="my-3"/>
                    <div class="row mb-2 align-items-center">
                        <div class="col-1 text-secondary">更多:</div>
                        <div class="col p-0">
                            <div class="col p-0 d-flex align-items-center">
                                <div v-show="metreArray.includes(useLandClassValue)" class="float-left">
                                    <dropdown @onChange="onAreaRangeP" :datas="dict.areaRangeP.filter(x=>x.id !='')" placeholder="选择面积" :active="arp" :width="180"></dropdown>
                                </div>
                                <div v-show="!metreArray.includes(useLandClassValue)" class="float-left">
                                    <dropdown @onChange="onAreaRangeM" :datas="dict.areaRangeM.filter(x=>x.id !='')" placeholder="选择面积" :active="arm"  :width="180"></dropdown>
                                </div>
                                <div class="float-left ml-2">
                                    <dropdown @onChange="onYears" :datas="dict.years.filter(x=>x.id != '')" placeholder="可流转年限" :width="180" ></dropdown>
                                </div>
                                <div class="float-left ml-2" >
                                    <b-form-tag v-if="para.param.title" @remove="onScreenClick('title','')"  size="lg" variant="success">{{ para.param.title }}</b-form-tag>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 py-2 mt-3 border rounded">
                    <div class="row pt-2 pb-2">
                        <div class="col-1 text-secondary mt-1">排序:</div>
                        <div class="col">
                            <div class="row">
                                <a href="javascript:;" @click="onSortClick(i)" v-for="(s,i) in sort" :key="i"  class="mr-4 px-2 py-1 rounded" :class="{'text-theme' : s.active}">{{s.text}} <b-icon :icon="s.orderBy == 'DESC' ? 'arrow-down-short' : 'arrow-up-short'"></b-icon></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container p-0">
            <div class="row my-4">
                <div class="col-9" v-if="list.records && list.records.length > 0">
                    <div class="row">
                        <div class="col-4 bg-white rounded" v-for="m in list.records" :key="m.id">
                            <land :datas="m" :height="200"></land>
                        </div>
                        <div class="col-12">
                            <div v-if="list && list.total > list.size" class="my-4 d-flex justify-content-center">
                                <b-pagination @change="onPageChange" :total-rows="list.total" :per-page="list.size" v-model="para.current"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-9" v-else>
                    <div class="border mb-3 p-3 rounded height-400">
                        <div class="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
                            <div class="text-gray-777">没有找到土地信息，您还可以免费</div>
                            <div class="ml-3">
                                <router-link to="/user/land/add" target="_blank">
                                    <b-button variant="success">发布土地</b-button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-3">
                    <div class="border-left menu-title px-3 mb-2 py-0">好地推荐</div>
                    <div class="row">
                        <div class="col-12 mb-3" v-for="m in rightLandList" :key="m.id">
                            <land-right :datas="m" :height="200"></land-right>
                        </div>
                    </div>
                    <div>
                        <a href="javascript:;" title="广告位1">
                            <img src="@/assets/img/ad_list_1.jpg" width="262"/>
                        </a>
                        <a href="javascript:;" title="广告位2">
                            <img class="mt-3" src="@/assets/img/ad_list_2.jpg" width="262"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <map-footer></map-footer>
    </div>

</template>

<script>
    import land from "@/components/base/land";
    import landRight from "@/components/right/land-right.vue";
    import {mapGetters} from "vuex";

    export default {
        components: {
            land,
            landRight,
        },
        data() {
            return {
                para: {
                    param: {
                        area: "",
                        title:"",
                        classId: "",
                        flowType: "",
                      serviceTag:"",
                        contacts: "",
                        contactNumber: "",
                        appointToLook: "",
                        measureAreaMin: '',
                        measureAreaMax: '',
                        yearsMin:'',
                        yearsMax:'',
                        landUse:""
                    }, size: 15,
                    current: 1,
                    sortBy: {}
                },
                useLandClassId:'',
                tags:'',
                arm:'',
                arp:'',
                years: '',
                chooseArea: {
                    province: '',
                    city: '',
                    area: '',
                },
                metreArray: ['商业及住宅','公共用地', '农房'],
                list:[],
                rightLandList:[],
                sort: [{
                    text: '最新',
                    filed: 'createdAt',
                    orderBy: 'DESC',
                    active: true,
                },{
                    text: '面积',
                    filed: 'measureArea',
                    orderBy: 'DESC',
                    active: false,
                },{
                    text: '价格',
                    filed: 'price',
                    orderBy: 'DESC',
                    active: false,
                },{
                    text: '年限',
                    filed: 'year',
                    orderBy: 'DESC',
                    active: false,
                }],
            };
        },
        computed: {
            ...mapGetters(["area", "dict"]),
            getCity() {
                if (this.area && this.chooseArea.province) {
                    let c = this.area.city.filter(x => (x.level == 2 && x.parentCode == this.chooseArea.province) || x.code == '');
                    if (c.length == 2) {
                        this.chooseArea.city = c[1].code;
                    } else {
                        return c;
                    }
                }
                return [];
            },
            getArea() {
                if (this.area && this.chooseArea.city) {
                    let a = this.area.area.filter(x => (x.level == 3 && x.parentCode == this.chooseArea.city) || x.code == '');
                    if (a) {
                        return a;
                    }
                }
                return [];
            },
            getAreaValue() {
                return function (id) {
                    let value = '';
                    if (this.area) {
                        let a = this.area.area.find(x => x.id == id)
                        if (a) {
                            value = a.name;
                            let c = this.area.city.find(x => x.code == a.parentCode);
                            if (c) {
                                value = value + ' ' + c.name;
                                let p = this.area.province.find(x => x.code == c.parentCode);
                                if (p) {
                                    value = value + ' ' + p.name;
                                }
                            }
                        }
                    }
                    return value.replace(' 市辖区', '')
                }
            },

            getChildUseLandList() {
                let c = this.dict.landUseType.find(x=> x.id == this.useLandClassId);
                if (c)  {
                    return c.children;
                }
                return []
            },
            useLandClassValue() {
                let c = this.dict.landUseType.find(x=> x.id == this.useLandClassId);
                if (c)  {
                    return c.text;
                }
                return ''
            },
          getTagsList() {
            debugger
            let c = this.dict.landTags.filter(x => x.parentId == this.tags);
            if (c) {
              debugger
              return c;
            }
            return []
          },

        },

        methods: {
            onPageChange(i) {
                this.para.current = i;
                this.getList();
            },
            onSortClick(i) {
                let o = this.sort.findIndex(x=> x.active == true);
                this.sort = this.sort.map(x=> { return {
                    text: x.text,
                    filed: x.filed,
                    orderBy: x.orderBy,
                    active: false
                }});
                if(o == i) {
                    this.sort[i].active = true;
                    this.sort[i].orderBy = this.sort[i].orderBy == 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.sort[i].active = true;
                }
                this.para.sortBy = {};
                this.para.sortBy[this.sort[i].filed] = this.sort[i].orderBy;
                this.getList();
            },
            onScreenClick(type, id) {
                this.para.current = 1;
                switch (type) {
                    case 'province':
                        this.chooseArea.province = id
                        this.chooseArea.city = '';
                        this.chooseArea.area = '';
                        this.para.param.area = id;
                        break;
                    case 'city':
                        this.para.param.area = id == '' ? this.chooseArea.province : id;
                        this.chooseArea.city = id;
                        this.chooseArea.area = '';
                        break;
                    case 'area':
                        this.para.param.area = id == '' ? this.chooseArea.city: id;
                        this.chooseArea.area = id;
                        break;
                    case 'landUseTypeP':
                        this.para.param.landUse= id;
                        this.useLandClassId = id;
                        if(this.metreArray.includes(this.useLandClassValue)) {
                                let p = this.dict.areaRangeP.find(x=>x.id == this.arp);
                                if(p) {
                                    this.para.param.measureAreaMin = p.measureAreaMin;
                                    this.para.param.measureAreaMax = p.measureAreaMax;
                                }
                            } else {
                                let m = this.dict.areaRangeM.find(x=>x.id == this.arm);
                                if(m) {
                                    this.para.param.measureAreaMin = m.measureAreaMin;
                                    this.para.param.measureAreaMax = m.measureAreaMax;
                                }
                            }
                        break;
                    case 'landUseTypeC':
                        this.para.param.landUse = id;
                        break;
                    case 'circulationType':
                        this.para.param.flowType = id;
                        break;
                    case 'title': 
                        this.para.param.title = ''
                        break;
                    case 'areaRangeM':
                        this.arm = id;
                        let m = this.dict.areaRangeM.find(x=>x.id == id);
                        if(m) {
                            this.para.param.measureAreaMin = m.measureAreaMin;
                            this.para.param.measureAreaMax = m.measureAreaMax;
                        }
                        break;
                    case 'areaRangeP':
                        this.arp = id;
                        let p = this.dict.areaRangeP.find(x=>x.id == id);
                        if(p) {
                            this.para.param.measureAreaMin = p.measureAreaMin;
                            this.para.param.measureAreaMax = p.measureAreaMax;
                        }
                        break;
                    case 'years':
                        this.years = id;
                        let y = this.dict.years.find(x=>x.id == id);
                        if(y) {
                            this.para.param.yearsMin = y.yearMin;
                            this.para.param.yearsMax = y.yearMax;
                        }
                        break;
                    case 'appointmentType':
                        this.para.param.appointToLook=id;
                        break;
                  case 'serviceTagP':
                    this.para.param.serviceTag=id;
                    this.tags=id;
                    break
                  case 'serviceTagC':
                    this.para.param.serviceTag = id;
                    break;

                }
                this.getList();

            },
            onAreaRangeM(a){
                this.onScreenClick('areaRangeM',a.id);
            },
            onAreaRangeP(a){
             this.onScreenClick('areaRangeP',a.id);
             },
            onYears(a){
                this.onScreenClick('years',a.id);
            },
            getList() {
                this.$http.post(this.$api.supply.getNeedsForLetList, this.para).then(res => {
                    if(res.code == 1) {
                        this.list = res.result;
                        this.list.records = this.list.records.map(x=> {
                            let f = x.userNeedsFiles.find(y=> y.uploadType == 1);
                            return {
                                id: x.id,
                                fileUrl: f ? f.fileUrl : '',
                                title: x.title,
                                price: x.price,
                                priceUnit: x.priceUnit,
                                measureArea: x.measureArea,
                                acreageUnit: x.acreageUnit,
                                year:x.years,
                                area:x.area
                            }
                        });
                    }
                })
            },
            getLandList() {
                this.$http.post(this.$api.supply.getNeedsForLetList, {
                    param: {classId: 1},
                    size: 4,
                    current: 6
                }).then((res) => {
                    this.rightLandList = res.result.records.map(x=> {
                        let f = x.userNeedsFiles.find(y=> y.uploadType == 1);
                        return {
                            id: x.id,
                            fileUrl: f ? f.fileUrl : '',
                            title: x.title,
                            price: x.price,
                            priceUnit: x.priceUnit,
                            measureArea: x.measureArea,
                            acreageUnit: x.acreageUnit,
                        }
                    });
                });
            },
        },
        created() {
            if(this.$route.query.province) {
                this.chooseArea.province = this.$route.query.province;
                this.para.param.area = this.chooseArea.province;
            }

            if(this.$route.query.city) {
                this.chooseArea.city = this.$route.query.city;
                this.para.param.area = this.chooseArea.city;
            }

            if(this.$route.query.area) {
                this.chooseArea.area = this.$route.query.area;
                this.para.param.area = this.chooseArea.area;
            }

            if(this.$route.query.classid) {
              debugger
                this.useLandClassId = this.$route.query.classid;
                this.para.param.landUse = this.useLandClassId;
            }

          if(this.$route.query.serviceTag) {
            this.tags = this.$route.query.tags;
            this.para.param.serviceTag = this.$route.query.serviceTag;
          }

            if(this.$route.query.childclassid) {
                this.para.param.landUse = this.$route.query.childclassid;
            }
            if(this.$route.query.title) {
                this.para.param.title = this.$route.query.title;
            }
            if(this.$route.query.arp) {
				this.arp = this.$route.query.arp;
				let p = this.dict.areaRangeP.find(x=>x.id == this.arp);
				if(p) {
					this.para.param.measureAreaMin = p.measureAreaMin;
					this.para.param.measureAreaMax = p.measureAreaMax;
				}
			} else {
				if(this.$route.query.arm) {
					this.arm = this.$route.query.arm;
					let m = this.dict.areaRangeM.find(x=>x.id == this.arm);
					if(m) {
						this.para.param.measureAreaMin = m.measureAreaMin;
						this.para.param.measureAreaMax = m.measureAreaMax;
					}
				}
			}
            this.getList();
            this.getLandList();
        },
    };
</script>

<style scoped>
    .screen .active {
        background: #00cc00;
        color: #FFFFFF;
        border-radius: 6px;
    }

    span {
        display: inline-block;
        text-align: center;
        font-size: 12px;
        margin-right: 4px;
        border-radius: 3px;
        line-height: 20px;
        padding: 0 8px;
        height: 20px;
        vertical-align: middle;

    }
    .contain img{
        height: 200px;
        width: 100%;
        display: block;
        object-fit: cover;
    }

</style>
